import { createSlice } from "@reduxjs/toolkit";

export const mySlice = createSlice({
  name: "frame",
  initialState:
  {
    frame : "Contant",
    email : "",
    remember : false,
    code : "",
    errorMsg : ["","",""],
    version : "V0.1011",
    alloutput_uptodate : true,
  },
  reducers: 
  {
    changeFrame(state,action){state.frame = action.payload;},
    changeEmail(state,action){state.email = action.payload;},
    changeRemember(state,action){state.remember = action.payload;},
    changeCode(state,action){state.code = action.payload;},
    changeError(state,action){state.errorMsg = action.payload;},
    setAllUpTodate(state,action){state.alloutput_uptodate = action.payload;},
  }
});

export const {changeFrame,changeEmail,changeRemember,changeData,changeCode,changeError,setAllUpTodate} = mySlice.actions;
export default mySlice.reducer;
