import { createSlice } from "@reduxjs/toolkit";
import {defultOkButton} from '../Data/Variables'
export const mySlice = createSlice({
  name: "modals",
  initialState:
  {
    modalsProps: {
        "modalCurrency" : false,"modalSplit" : false
        ,"modalWarningVar" : false,"modalGenerate" : "false"
        ,"modalFilter" : false,"modalCustom" : false,"modalRename" : false,"budgetModel" : false},
    buttonOkLoad : defultOkButton,
  },
  reducers: 
  {
    setButtonOkLoad(state,action){ state.buttonOkLoad = action.payload;},
    setModalsProps(state,action){ state.modalsProps = action.payload;},
  }
});

export const {setModalsProps,setButtonOkLoad} = mySlice.actions;
export default mySlice.reducer;
